import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/products.scss"
import SingleProduct from "../../shared-component/single-product-page"
import { productsData } from './index'

const data = {
  prodHeading: productsData[0].heading,
  prodDesc:'Make better operational decisions by monitoring plant performance in real-time.',
  prodImg: productsData[0]['prodImage'],
  subDesc:'In this competitive world, having accurate data at the right time gives you a competitive edge. Our IoT kit allows you to access data on critical metrics such as production process, units manufactured, machine ideal time and many more, in real-time. Helps in real-time diagnosis like machine imbalance, cavitation, vibration suppression methods, deflection shape analysis etc.',
  para:[
    {
      content:[
        {
          head:'Remote Monitoring Dashboard',
          desc:'Get a pre-defined, user friendly customized dashboard for monitoring your machine parameters in real time. KPIs, Parameters, Trends, Alerts, Threshold Settings, User Management.'
        }
      ],
      img:'/static/svgs/monitoring.svg'
    },
    {
      content:[
        {
          head:'Communicates with the cloud via Wifi or GSM',
          desc:"What happens if the Wifi connectivity breaks suddenly? You don’t have to worry about data loss related to lack of connectivity. Our IoT gateways are equipped to communicate with the cloud over WiFi or GSM. This feature ensures constant connectivity with the cloud and data continuity."
        },
        {
          head:'Doesn’t interfere with existing systems',
          desc:"Are you worried that our IoT gateways will disrupt or cause malfunctions of any sort in the existing systems? Don’t worry. All your existing systems are guaranteed to function as seamlessly as they already do. Our Integrated Industrialized Gateways will never cause any interference or obstruction of any kind."
        },
     ],
      img:'/static/svgs/wifi-svg.png'
    },
    {
      content:[
        {
          head:'Explicitly designed for Industrial Use Cases',
          desc:'Standardization has been one of our top priorities when designing our products. We have ensured that whatever be the machine’s manufacturer and technology, you can confidently use our products. Our vibrational analysis kit works on LoRa, WiFi & Cellular technology. That’s why our product can be used in any machine across all industries.'
        },
        {
          head:'Can be retrofitted',
          desc:'Often you need to buy additional components before you can install something. For our vibrational analysis kit to work, your machines don’t need any specific pre-installed parts. Everything that’s required is already present in the kit. All you have to do is install it, and you are good to go.'
        },
     ],
      img:'/static/svgs/Artboard 26.svg'
    },
  ],
  tableData:[
    {
      heading:'PLC Drivers',
      content:'Siemens, Rockwell, Beckhoff, Mitsubishi'
    },
    {
      heading:'Communication Ports',
      content:'USB 2.0, Multiple Ethernet RJ45 Port, Supports RS232 MODBUS Protocol'
    },
    {
      heading:'Wireless',
      content:'Wi-Fi, GSM'
    },
    {
      heading:'OPC Clients',
      content:'OPC DA, OPC UA'
    },
    {
      heading:'Machine Drivers',
      content:'Energy Meter Drivers, Compressors, Recorders'
    },
    {
      heading:'Database',
      content:'SQL, MongoDB, InfluxDB'
    },
    {
      heading:'Protocol',
      content:'MQTT, HTTP'
    },
    {
      heading:'Required Supply Voltage',
      content:'230VAC'
    },
    {
      heading:'Operating Temp.',
      content:'-15°C to 60°C'
    },
  ]

}



const FlashIotKit = () => (
  <Layout>
    <SEO title="Flash IOT Kit" />
     <SingleProduct data={data}/>
  </Layout>
)

export default FlashIotKit
